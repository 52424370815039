<template>
    <div class="main">
        <div class="content-top-box">
            <div class="top-title-box">
                <div class="top-title">{{query.jobName}}</div>
                <div class="position-info">
                    <div>{{query.provinceName}}/{{query.cityName}}</div>
                    <div>薪酬：<span class="position-info-num">{{query.salaryMin/1000}}-{{query.salaryMax/1000}}k</span></div>
                </div>
            </div>
        </div>
        <div class="content-center-box">
            <div class="position-show-title">
                <span class="position-show-title-icon">.</span>
                <span class="position-show-title-text">投递信息</span>
            </div>
            <div class="item-box">
                <div class="item-title">姓名</div>
                <div class="item-input-box">
                    <input v-model="addData.name" class="item-input" placeholder="请输入"/>
                </div>
            </div>
            <div class="item-box">
                <div class="item-title">性别</div>
                <div @click="showBox = true" class="item-input-box">
                    <input v-model="sex" class="item-input" disabled placeholder="请输入"/>
                    <img class="item-input-icon" src="../../assets/image/arrow-right.png">
                </div>
            </div>
            <div class="item-box">
                <div class="item-title">出生年月</div>
                <div @click="dateShow = true" class="item-input-box">
                    <input v-model="addData.birthday" class="item-input" disabled placeholder="请输入"/>
                    <img class="item-input-icon" src="../../assets/image/arrow-right.png">
                </div>
            </div>
            <div class="item-box">
                <div class="item-title">手机号</div>
                <div class="item-input-box">
                    <input v-model="addData.mobile" type="number" oninput="if(value.length>11)value=value.slice(0,11)" class="item-input" placeholder="请输入"/>
                </div>
            </div>
            <div class="item-box">
                <div class="item-title">验证码</div>
                <div class="item-input-box2">
                    <input v-model="addData.smsCode" type="number" oninput="if(value.length>4)value=value.slice(0,4)" class="item-input2" placeholder="请输入"/>
                    <div @click="toSend" class="auth-code">{{smsText}}</div>
                </div>
            </div>
        </div>
        <div class="content-center-box">
            <div class="position-show-title">
                <span class="position-show-title-icon">.</span>
                <span class="position-show-title-text">附件简历</span>
            </div>
            <div v-if="addData.resumeUrl === ''">
                <van-uploader :after-read="afterRead" />
            </div>
            <div class="file-title" v-else>
                <div>{{fileTitle}}</div>
                <img @click="delFile" class="close-img" src="../../assets/image/close.png">
            </div>
        </div>
        <div class="bottom-box">
            <div @click="toSubmit" class="sub-text">投递</div>
        </div>
        <div class="bottom-blank"></div>
        <!--选择性别开始-->
        <div class="select-type-box" v-if="showBox">
            <van-picker
                    title="请选择性别"
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="onCancel"
            />
        </div>
        <!--选择岗位结束-->
        <!--选择日期开始-->
        <div class="select-type-box" v-if="dateShow">
            <van-datetime-picker
                    v-model="addData.birthday"
                    type="date"
                    title="选择年月日"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm = dateConfirm
                    @cancel="dateShow = false"
                    @change="updateDate"
            />
        </div>
        <!--选择日期结束-->
        <van-overlay :show="show" @click="show = false" />
    </div>
</template>

<script>
    import { uploadPersonnel, submitPersonnel, sendSms } from '@/api/service'
    import { Toast } from 'vant';
    // import { Dialog } from 'vant';
    // import Cookies from 'js-cookie'
    export default {
        name: 'Reward',
        components: {
        },
        data() {
            return {
                userId: '', // 用户id
                nowUserInfo: {}, // 当前用户信息
                showBox: false,
                dateShow: false,
                show: false,
                query: '',
                isSend: true,
                minDate: new Date(1950, 0, 1),
                maxDate: new Date(2025, 10, 1),
                columns: ['男','女'],
                fileTitle: '',
                sex: '',
                smsText: '获取验证码',
                nums: 60,
                clock: '',
                subIng: false,
                addData: {
                    name: '',
                    sex: '', //0女1男
                    birthday: '',
                    mobile: '', //手机号码
                    resumeUrl: '',
                    recommendId: '',
                    recruitTaskId: '',
                    jobId: '',
                    smsCode: '',
                    provinceName: '',
                    cityName: ''
                }
            }},
        created() {
            this.query = this.$route.query
            if(!this.query.id){
                Toast('参数错误')
            }
            this.addData.jobId = this.query.id
            if(!this.query.taskId){
                Toast('参数错误')
            }
            this.addData.recruitTaskId = this.query.taskId
            if(!this.query.shareId){
                Toast('参数错误')
            }
            this.addData.provinceName = this.query.provinceName
            this.addData.cityName = this.query.cityName
            this.addData.recommendId = this.query.shareId
        },
        mounted:function(){

        },
        methods: {
            onConfirm(value) {
                this.sex = value
                this.showBox = false
            },
            onCancel() {
                this.showBox = false
            },
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                console.log(file);
                this.fileTitle = file.file.name
                const fd = new FormData()
                fd.append('file', file.file)
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                });
                uploadPersonnel(fd).then(response => {
                    this.addData.resumeUrl = response.data
                    Toast.clear();
                }).catch(() => {
                })
            },
            updateDate(){
                this.addData.birthday = this.formatDate(this.addData.birthday);
            },
            formatDate(date) {
                let myyear = date.getFullYear();
                let mymonth = date.getMonth() + 1;
                let myweekday = date.getDate();

                if (mymonth < 10) {
                    mymonth = "0" + mymonth;
                }
                if (myweekday < 10) {
                    myweekday = "0" + myweekday;
                }
                return (myyear + "-" + mymonth + "-" + myweekday);
            },
            dateConfirm(value) {
                this.dateShow = false;
                console.log(value)
                this.addData.birthday = this.formatDate(value);
            },
            delFile(){
                this.addData.resumeUrl = ''
                this.fileTitle = ''
            },
            // 提交数据
            toSubmit(){
                if(this.subIng) {
                    return
                }
                this.subIng = true
                if(this.addData.name === '') {
                    Toast('请先输入姓名')
                    return
                }
                if(this.sex === '') {
                    Toast('请先选择性别')
                    return
                }
                if(this.addData.birthday === '') {
                    Toast('请先选择生日')
                    return
                }
                if(this.addData.mobile === '') {
                    Toast('请先输入手机号')
                    return
                }
                if(this.addData.smsCode === '') {
                    Toast('请先输入验证码')
                    return
                }
                if(this.sex === '男'){
                    this.addData.sex = 1
                }else{
                    this.addData.sex = 0
                }
                submitPersonnel(this.addData).then((response) => {
                    this.subIng = false
                    console.log(response)
                    if(response !== undefined) {
                        this.toSuccess()
                        Toast('提交成功')
                    }
                })
            },
            // 发送数据
            toSend(){
                if(this.addData.mobile === '') {
                    Toast('请先输入手机号')
                    return
                }
                if(!this.isSend) {
                    return
                }
                if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.addData.mobile))){
                    Toast('手机号码有误，请重填')
                    return
                }
                let postData = {}
                postData.mobile = this.addData.mobile
                sendSms(postData).then((response) => {
                    console.log(response)
                    this.clock = setInterval(this.doLoop, 1000)
                    this.isSend = false
                    Toast('发送成功')
                })
            },
            // 跳转成功页面
            toSuccess() {
                this.$router.push({ path: '/success', query: {}})
            },
            doLoop(){
                this.nums--;
                if(this.nums > 0){
                    this.smsText = this.nums+'秒';
                }else{
                    clearInterval(this.clock); //清除js定时器
                    this.isSend = true
                    this.smsText = '发送验证码';
                    this.nums = 60; //重置时间
                }
            }
        }
    }
</script>

<style scoped>
    .main{
        width: 100vw;
        min-height: 100vh;
        position: relative;
        background: #F8F8F8;
    }
    .content-top-box{
        padding-top: 20px;
    }
    .top-title{
        height: 22px;
        line-height: 22px;
        font-weight: bold;
        font-size: 16px;
    }
    .position-info{
        display: flex;
        justify-content: space-between;
        color: #606166;
        font-size: 13px;
        margin-top: 10px;
    }
    .position-info-num{
        color: #F98148 ;
    }
    .top-title-box{
        margin: 0 20px 20px 20px;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 4px;
    }
    .bottom-blank{
        width: 100vw;
        height: 30px;
    }
    .position-show-title-icon{
        width: 3px;
        height: 12px;
        background: #2586FF;
        color: #2586FF;
        margin-right: 10px;
    }
    .position-show-title-text{
        font-size: 16px;
        font-weight: bold;
    }
    .position-show-title{
        height: 50px;
        line-height: 50px;
    }
    .content-center-box{
        padding: 20px;
        background-color: #ffffff;
        margin: 0 20px 20px 20px;
        border-radius: 4px;
    }
    .item-title{
        height: 18px;
        line-height: 18px;
        font-size: 15px;
        font-weight: 400;
        color: #999999;
    }
    .item-input-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        width: calc(100vw - 80px);
        background: #F5F7FB;
    }
    .item-input{
        width: calc(100vw - 150px);
        border: none;
        padding: 10px;
        text-align: left;
        background: #F5F7FB;
        border-radius: 2px;
    }
    .item-input-icon{
        width: 20px;
        height: 20px;
    }
    .item-input-box2{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        width: calc(100vw - 80px);
    }
    .item-input2{
        width: calc(100vw - 220px);
        border: none;
        padding: 10px;
        text-align: left;
        background: #F5F7FB;
        border-radius: 2px;
    }
    .auth-code{
        width: 99px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 2px;
        border: 1px solid #CCCCCC;
        color: #f6404f;
        font-size: 14px;
    }
    .select-type-box{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
    }
    .file-title{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
    .close-img{
        width: 20px;
        height: 20px;
    }
    .sub-text{
        width: 313px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #ffffff;
        background: linear-gradient(131deg, #69ACFF 0%, #2586FF 100%);
        border-radius: 22px;
    }
    .bottom-box{
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
</style>
